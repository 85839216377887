<template>
  <div>
    <form
      @submit.prevent="submitHandler"
      v-form-enter
      v-if="!isLoading"
      style="margin-bottom: 250px"
    >
      <div class="formgrid grid flex align-items-center pb-3">
        <div class="field col-6">
          <h2>
            <template v-if="uid">Edit</template
            ><template v-else>Add</template> Department
          </h2>
        </div>
        <div class="field col-6">
          <div class="p-inputgroup flex justify-content-end">
            <AInputSwitch v-model="department.active" class="green" />
          </div>
        </div>
        <div class="field col-12 sm:col-6">
          <label for="name">Department Name</label>
          <div class="p-inputgroup">
            <InputText
              v-model="department.department_name"
              class="green"
              placeholder="Department Name"
            />
          </div>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.department.department_name.required"
          >
            Department name is required
          </div>
        </div>
        <div class="field col-12 sm:col-6">
          <label class="label">Default Hourly Rate</label>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">{{ currency }}</span>
            <InputNumber
              :minFractionDigits="2"
              locale="en-US"
              class="input"
              v-model="department.rate"
            />
          </div>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.department.rate.required"
          >
            Default hourly rate is required
          </div>
        </div>
        <div class="field col-12 sm:col-6">
          <label for="name">Sales Code</label>
          <div class="p-inputgroup">
            <Dropdown
              v-if="!isLoading"
              :options="salesCodes"
              placeholder="Select Sales Code"
              optionValue="uid"
              optionLabel="integration_reference"
              class="input"
              v-model="department.gl_sales_uid"
            />
            <Skeleton height="100%" borderRadius="6px" v-else />
          </div>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.department.gl_sales_uid.required"
          >
            Sales Code is required
          </div>
        </div>
        <div class="field col-12 sm:col-6">
          <label for="name">Cost of Sales Code</label>
          <div class="p-inputgroup">
            <Dropdown
              v-if="!isLoading"
              :options="cosCodes"
              placeholder="Select Cost of Sales Code"
              optionValue="uid"
              optionLabel="integration_reference"
              class="input"
              v-model="department.gl_cos_uid"
            />
            <Skeleton height="100%" borderRadius="6px" v-else />
          </div>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.department.gl_cos_uid.required"
          >
            Cost of Sales Code is required
          </div>
        </div>
      </div>

      <div class="flex justify-content-between pt-5">
        <Button type="submit"> SAVE </Button>
        <Button
          class="p-button-text p-button-secondary"
          @click="clearSliderContent"
        >
          CANCEL
        </Button>
      </div>
    </form>
    <RocketLoad :full="true" v-else />
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { fetchAccountFinance, state } from "../../../services/data_service";
export default {
  name: "UserSliderDetails",
  components: {},
  data() {
    return {
      submitted: false,
      formOptions: [],
      name: null,
      department: {
        uid: null,
        department_name: null,
        rate: null,
        gl_sales: null,
        gl_sales_uid: null,
        gl_cost_of_sales: null,
        gl_cos_uid: null,
        active: 1,
      },
      defaultRates: null,
      defaultGlCodes: null,
      glCostOfSales: null,
      myFiles: [],
      email: "",
      profilePicture: "",
      isLoading: false,
      loadingText: "Loading...",
      inputIsVisible: false,
    };
  },
  validations: {
    department: {
      department_name: {
        required,
      },
      rate: {
        required,
      },
      gl_sales_uid: {
        required,
      },
      gl_cos_uid: {
        required,
      },
    },
  },
  async mounted() {
    this.isLoading = true;
    await fetchAccountFinance();
    if (this.selectedObject) {
      this.department = this.selectedObject;
    }
    this.isLoading = false;
  },
  methods: {
    submitHandler() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toast.add({
          severity: "error",
          summary: "Please complete all the required fields",
          life: 3000,
        });
        return;
      }

      this.isLoading = true;
      this.loadingText = "Processing...";
      var form = new FormData();
      form.append("department_name", this.department.department_name);
      form.append("rate", this.department.rate);
      form.append("gl_sales_uid", this.department.gl_sales_uid);
      form.append("gl_cos_uid", this.department.gl_cos_uid);

      if (this.selectedObject) {
        form.append("uid", this.selectedObject.uid);
      }

      form.append("active", this.department.active);

      this.$axios
        .post(
          process.env.VUE_APP_ROOT_API +
            "/v1/departments" +
            (this.selectedObject ? "/" + this.selectedObject.uid : ""),
          form,
          {
            headers: { "Content-type": "application/x-www-form-urlencoded" },
          }
        )
        .then((response) => {
          this.isLoading = false;
          this.clearSliderContent();
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response && error.response.status === 401) {
            console.error("NOOOOOOOOO!");
          } else {
            // Handle error however you want
          }
          console.error(error);
        });
    },
  },
  computed: {
    accountFinance() {
      return state.accountFinance;
    },
    salesCodes() {
      return this.accountFinance?.filter((a) => a.account_type === "sales");
    },
    cosCodes() {
      return this.accountFinance?.filter((a) => a.account_type === "cos");
    },
    user_uid() {
      return this.$store.getters.user.uid;
    },
    uid() {
      return this.selectedObject?.uid;
    },
    user() {
      return this.$store.getters.user;
    },
    selectedObject() {
      return this.$store.getters.selectedObject;
    },
    currency() {
      return this.$store.getters.accountFinance?.currency?.description ?? "R";
    },
  },
  watch: {},
};
</script>